import React from 'react'
import ted from '../images/ted.jpg'
import sam from '../images/sam.jpg'
import josh from '../images/josh.jpg'
const Home = () => {
  return (
    <div>
        <div className='homeImageContainer'>
        

            <div className='homeImages'>
                <img src={ted} alt="ted"/>
            </div> 
            <div className='homeImages'>
                <img src={josh} alt="josh"/>
            </div> 
            <div className='homeImages'>
                <img src={sam} alt="sam"/>
            </div>         

        </div>
        <div className='homeContainer' style={{marginTop: '50px'}}>
            This is a portal to give sales statistics for any given time period. It also tells us which customers make the most orders, so we know who to look after, and can see who is up and coming at a glance. It was a good project undertaking enabling me to learn a modern web library for building websites easily and interactively.<br></br><br></br>This gives us exact sales of items but when its calculating pallets to order it doesn't consider bottle sizes or kegs so it is a guide to help rather than an exact science. If there are improvements or things which can make life easier please let me know. We can extract any data we want from xero and I can put that data here and run calculations to help make forecasts or establishing who is selling what and how much.
        </div>

    </div>
    
  )
}

export default Home
