//CHILD COMPONENT
import React from 'react'
import Breweries from './Breweries'
import { useEffect,useState } from 'react';
import PalletCalcs from './PalletCalcs';

const CurrentStock = () => {
  
  const [duvel, setDuvel] = useState([]);
  const [weynants, setWeynants] = useState([]);
  const [huyghe, sethuyghe] = useState([]);
  const [vans, setvs] = useState([]);

  const [calcsDuvl,setCalcsDuvel] = useState([]);
  const [calcsWeynants,setCalcsWeynants] = useState([]);
  const [calcshuyghe,setCalcshuyghe] = useState([]);
  const [calcsvs,setCalcsvs] = useState([]);

  const [currentStockResults, setCurrentStockResults] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      const currentStock = await fetch(`https://stock.maltabrew.com/webservice/?action=currentStocklevel`);
      const currentStockResults = await currentStock.json();
      setCurrentStockResults(currentStockResults);
    };

    fetchData();
  }, []);
  
    const populateBreweryComponent = async (data,id) => {

      const res = await fetch(`https://stock.maltabrew.com/webservice/?action=getBeerInfo&startdate=${data[0]}&endDate=${data[1]}&id=${id}`);
      const beerresults = await res.json();

      let counter = 1;
      

      const allBeerResultsArrays = Object.keys(beerresults).map((individualBeerArrays, index) => {
        return beerresults[individualBeerArrays].map((items,index) => {
          let description = '';
          let quantity = '';
            if(index % 2 === 0){
              description = items;
              if(description.length > 20) description = description.substring(0,20);
            }else{
              quantity = items;
            }            
            
            return <div key={counter++}><div className='descriptionItem'>{description}</div><div className='quantityItem'>{quantity}</div></div>;
            
        })

      });

      const doCalcs = beerresults.duvel.map((items,index) =>{
       
        let description = '';
        let quantity = '';
        
            if(index % 2 === 0){

              description = items;
                          
              if(description.length > 15) description = description.substring(0,15);
             
            }else{
               
              quantity = Math.round(items/24);
               
            }            
            
            return <div key={counter++}><div className='descriptionItem'>{description}</div><div className='quantityItem'>{quantity}</div></div>;
      })
   
    switch(id){
      case '-d':
        setDuvel(allBeerResultsArrays);
        setCalcsDuvel(doCalcs);
      break;
      case '-w':
        setWeynants(allBeerResultsArrays);
        setCalcsWeynants(doCalcs);
      break;
      case '-h':
        sethuyghe(allBeerResultsArrays);
        setCalcshuyghe(doCalcs);
      break;
      case '-v':
        setvs(allBeerResultsArrays);
        setCalcsvs(doCalcs);
      break;

    }
  }

    const clearResults = (flag) => {
      
      switch (flag){
        case 'd' :
          setDuvel([]);
        break;
        case 'h' :
          sethuyghe([]);
        break;
        case 'w' :
          setWeynants([]);
        break;
        case 'v' :
          setvs([]);
        break;
        default:
      }
    }

    const getPallets = (args,str) => {
      
      let result = [];
      let total = 0;
      const palletsArr = args.map((items,index) => {
        if(index % 2 === 1){
        result.push(items.props.children[1].props.children);
        }
      });
      for(let i in result){
          total += result[i];
      }
      switch (str){
        case 'd' :
          return Math.round((total / 72) * 100) / 100;
        
        case 'h' :
          return Math.round((total / 68) * 100) / 100;
        
        case 'w' :
          return Math.round((total / 50) * 100) / 100;
        
        case 'v' :
          return Math.round((total / 64) * 100) / 100;
        
        default:
      }
    }
  
  return (
    <div className='mainContainer'>
      
      <div className='brewer'>
      
      <Breweries text={`DUVEL - NUM SOLD FOR DATE SPECIFIED`} id={'-d'} duvel={duvel} dateAndIdFunction={populateBreweryComponent}/>
      <div style={{height: '25px'}}>
      {duvel.length > 0 ? <button style={{marginLeft: '10px'}} className='btnItemsSold' onClick={() => clearResults('d')}>Clear</button> : null}
      </div>
      
      <Breweries text={'HUYGHE - NUM SOLD FOR DATE SPECIFIED'} id={'-h'} huyghe={huyghe} dateAndIdFunction={populateBreweryComponent}/>
      <div style={{height: '25px', marginBottom: '10px'}}>
      {huyghe.length > 0 ? <button style={{marginLeft: '10px'}} className='btnItemsSold' onClick={() => clearResults('h')}>Clear</button> : null}
      </div>
      </div>

      <div className='brewer'>

      <Breweries text={'WEYNANTS - NUM SOLD FOR DATE SPECIFIED'} id={'-w'} weynants={weynants} dateAndIdFunction={populateBreweryComponent}/>
      <div style={{height: '25px', marginBottom: '10px'}}>
      {weynants.length > 0 ? <button style={{marginLeft: '10px'}} className='btnItemsSold' onClick={() => clearResults('w')}>Clear</button> : null}
      </div>
      <Breweries text={'VAN STEENBERG - NUM SOLD FOR DATE SPECIFIED'} id={'-v'} vans={vans} dateAndIdFunction={populateBreweryComponent}/>
      <div style={{height: '25px', marginBottom: '10px'}}>
      {vans.length > 0 ? <button style={{marginLeft: '10px'}} className='btnItemsSold' onClick={() => clearResults('v')}>Clear</button> : null}
      </div>  
      
      
      </div>
      <div className='palletCalcsFront'>
      <div className='palletsTitle'>APPROXIMATE NUMBER OF PALLETS.</div>
      { duvel.length > 0 ? <PalletCalcs text={'DUVEL CASES'} calcs={calcsDuvl}/> : null }
      { duvel.length > 0 ? <h4 className='titleStyle'>PALLETS<div className='quantityItem'>{getPallets(calcsDuvl,'d')}</div></h4> : null }

      { huyghe.length > 0 ? <PalletCalcs text={'HUYGHE CASES'} calcs={calcshuyghe}/>  : null }
      { huyghe.length > 0 ? <h4 className='titleStyle'>PALLETS<div className='quantityItem'>{getPallets(calcshuyghe,'h')}</div></h4> : null }

      { weynants.length > 0 ? <PalletCalcs text={'WEYNANTS CASES'} calcs={calcsWeynants}/>  : null }
      { weynants.length > 0 ? <h4 className='titleStyle'>PALLETS<div className='quantityItem'>{getPallets(calcsWeynants,'w')}</div></h4> : null }

      { vans.length > 0 ? <PalletCalcs text={'VAN STEENBERG CASES'} calcs={calcsvs}/>  : null }
      { vans.length > 0 ? <h4 className='titleStyle'>PALLETS<div className='quantityItem'>{getPallets(calcsvs,'v')}</div></h4> : null }

      <hr></hr><div className='currentStockTed'>Current stock level for each product.</div>
      {currentStockResults.map((item, index) => (
        <div key={index}>
          <div>{item.title} - <span>{item.quantity}</span></div>
        </div>
      ))}

      </div>
      
      
      
    </div>
  )
  
}

export default CurrentStock
