import React, { useRef } from 'react'
import { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
//PARENT COMPONENT

const Breweries = (props) => {
  
  const [startdate, setStartDate] = useState(new Date());
  const [enddate, setEndDate] = useState(new Date());

  const constructDate = () => {

    let startDay = startdate.getDate();
    let endDay = enddate.getDate();
    let startMonth = startdate.getMonth() + 1;
    let endMonth = enddate.getMonth() + 1;
    let startYear = startdate.getFullYear();
    let endYear = enddate.getFullYear();

    let datesChosen = [`${startYear}-${startMonth}-${startDay}`,`${endYear}-${endMonth}-${endDay}`];
    
    props.dateAndIdFunction(datesChosen,props.id);
  
  }
  
  return (
    
    <div className='breweries'>
    <h4 className='titleStyle'><b>{props.text}</b></h4>
    <div className='dateContainer'>
      <div className='dateContainerChildren'>
      <DatePicker
          
          selected={startdate}
          onChange={(startdate) => setStartDate(startdate)}  //only when value has changed
          selectsStart
          startDate={startdate}
          endDate={enddate}
        />
      </div>
      <div className='dateContainerChildren'>
        
        <DatePicker
        
        selected={enddate}
        onChange={(enddate) => setEndDate(enddate)}  //only when value has changed
        selectsEnd
        startDate={startdate}
        endDate={enddate}
        minDate={startdate}
        
        />
        </div>
        <button className='btnItemsSold' onClick={(e) => constructDate(e)}>Get</button>
        
    </div>  

      <br></br>
      <div style={{marginLeft: '4px', fontFamily: 'Sans-serif'}}>
        
        {props.duvel}
        {props.weynants}
        {props.huyghe}
        {props.vans}
        
      </div>  
      
    </div>

  )
}

export default Breweries


