import React from 'react'
import { useEffect } from 'react';

const PalletCalcs = (props) => {
  
  return (
    <div className='palletCalcs'>
      
      <h4 className='titleStyle'><b>{props.text}</b></h4>
      <div style={{marginLeft: '4px', marginBottom: '10px',fontFamily: 'Sans-serif'}}>

      {props.calcs}
      </div>
      
    </div>
  )
}

export default PalletCalcs
