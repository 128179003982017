import React from 'react'
import {  Link } from "react-router-dom";

const Navigation = () => {

  return (
    
        <div className='navComp'>

        <Link className='navItems' to={"/"}>HOME</Link>
        <Link className='navItems' to={"sales"}>SALES</Link>
        <Link className='navItems' to={"customers"}>CUSTOMERS</Link>

        </div>

    
  )
}

export default Navigation
