//import { getValue } from '@testing-library/user-event/dist/utils';
import React from 'react';
import { useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const CustomerStats = (props) => {
    
    const [startdate, setStartDate] = useState(new Date());
    const [enddate, setEndDate] = useState(new Date());
    
    const [results, setCustResults] = useState([]);

        const constructDate = () => {
      
          let startDay = startdate.getDate();
          let endDay = enddate.getDate();
          let startMonth = startdate.getMonth() + 1;
          let endMonth = enddate.getMonth() + 1;
          let startYear = startdate.getFullYear();
          let endYear = enddate.getFullYear();
      
          let datesChosen = [`${startYear}-${startMonth}-${startDay}`,`${endYear}-${endMonth}-${endDay}`];
          
          getCustomerFrequency(datesChosen);
        
        }
        
        const getCustomerFrequency = async (data) => {

            let counter = 1;

            const res = await fetch(`https://stock.maltabrew.com/webservice/?action=getCustomerInfo&startdate=${data[0]}&endDate=${data[1]}`);
            const customerResults = await res.json();

            const customerResultsArrays = Object.keys(customerResults).map((custArrays, index) => {
            
            return customerResults[custArrays].map((items) => {
            
                return <div key={counter++}><div className=''>{items}</div></div>;

                })
            })   
            setCustResults(customerResultsArrays);
        }
        
    return (

        <div className="customerMainContainer">
          <div>Frequency of orders in given time period.</div>
        <div className='dateContainer'>
        
        <div className='dateContainerChildren'>
         
        <DatePicker
        
          selected={startdate}
          onChange={(startdate) => setStartDate(startdate)}  //only when value has changed
          selectsStart
          startDate={startdate}
          endDate={enddate}

        />
        </div> 
        <div className='dateContainerChildren'> 
        <DatePicker
        
         selected={enddate}
         onChange={(enddate) => setEndDate(enddate)}  //only when value has changed
         selectsEnd
         startDate={startdate}
         endDate={enddate}
         minDate={startdate}
        
        />
        </div>
        
        <button className='btnItemsSold' onClick={(e) => constructDate(e)}>Get</button>
        </div>

        <div>{results}</div>
        
        </div>

    )
}


export default CustomerStats
