import React from 'react';
import './App.css';
import { useState } from 'react';
import Header from './components/Header'
import CurrentStock from './components/CurrentStock';
import Home from './components/Home';
import { Routes, Route } from "react-router-dom";
import CustomerStats from './components/CustomerStats';

const App = () => {

  const [pass,setPass] = useState([]);
  const sendCreds = async (argument) => {
    const fetchurl = `https://stock.maltabrew.com/webservice/?action=login&email=${argument.userdata}&password=${argument.passdata}`;
    const res = await fetch(fetchurl,{method: 'POST'});
      const data = await res.json();
      setPass(data);

  }
    
    if(pass.status === 'OK'){
    
    return (
      
      <div className="App">
        <Header setPassAndUserData={sendCreds} />
        
        <Routes>

          <Route path="/sales" element={<CurrentStock/>}/>
          <Route path="/customers" element={<CustomerStats/>}/>
          <Route path="/" element={<Home/>}/>

        </Routes>
      </div>
    );
    }else{
      
      return (
        <div className="App">
        <Header setPassAndUserData={sendCreds}/>
      </div>
      )
    }
}

export default App;
