import React from 'react'
import { useState } from 'react';
import Navigation from './Navigation';

const Header = ({setPassAndUserData,aRouteFunc}) => {
  
  const [userdata, setUserData] = useState('');
  const [passdata, setPassData] = useState('');
  
 const myFunction = (e) => {
  
  e.preventDefault();
  
  setPassAndUserData({userdata,passdata});
  
 }


  return (
    
    <div className='loginContainer'>
      <h2 className='titleHeader'>STOCK FORECASTING</h2>

        <Navigation/>

        <form onSubmit={myFunction}>
        <input onChange={e => setUserData(e.target.value)} className='loginInputBox' type='text' name='user' placeholder='email'/>
        <input onChange={e => setPassData(e.target.value)} className='loginInputBox' type='password' name='pass' placeholder='password'/>
        <input className='submitInputButton' type='submit' value='Submit'/>
        </form>

    </div>

  )
}

export default Header
